.h-wrapper{
    background-color:rgb(24, 24, 24);
}
.h-container{
    display: 100%;
    justify-content:space-between;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;    
 }
.h-menu{
  gap: 2rem;
  padding-right: 25px;
}
.flexCenter{
    display:flex;
    row-gap:20 rem;
    align-items:center;
    flex-wrap:wrap;
  }
  a{
  color: aliceblue;
  text-decoration: none;
  }
  .padding{
    padding: 1.5 rem;
  }
  .innerwidth{
    width:100%; 
  }
  .button{
font-weight: 500;
padding:0.6rem 1.4 rem;
color:aliceblue;
background-color:rgb(158, 80, 231) ;
border:none;
border-radius:4px;
transition: all 300ms ease-in;
  }
  .button:hover{
    cursor: pointer;
    transform: scale(1.15 );
  }
  /* .flexstart{
    display:flex;
    justify-content: flex-center;
    align-items: center;
  }
  .flexend{
    display:flex;
    justify-content: flex-end;
    align-items: center;
  }
  .flexcolcenter{
    display: flex;

    align-items: center;
    flex-direction: column;
  }
     */
    