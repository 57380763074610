.hero-wrapper{
   background-color: black;
    position: relative;
    padding-top: 3rem;
    height: 600px;
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;
    height: 601px;
}
.image-container{
    width:30rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 200rem 200rem 0 0;
    border: 8px solid greenyellow;
    margin-bottom: 150px;
}

.image-container>img{
  width:100%;
  height:100%;
}
.search-bar,h1,span{
    color: rgb(108, 72, 72);
}
.flexcolstart{
    display:flex;
    flex-direction: column;
    align-items: center;
   align-items: flex-start;
   margin-bottom: 20rem;
}

.hero-left{
    gap:.5rem;
}
.hero-right{
    margin-bottom: 35rem;
}
.hero-title{
    position: relative;
    z-index: 1;
}
.orange-circle{
    height:3rem;
    width: 3rem;
    background-color: rgb(228, 136, 66);
    border-radius: 900px;
    position: absolute;
    right:30%;
    top: 11%;
    z-index: -1;
}
.search-bar{
    margin-top:-200px;
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    bottom: 60px;
}

.search-bar>img{
   height: 35px;
   
   color: aliceblue;
 position: absolute;
 display: grid;
 place-items: center;
 left:1rem;
 border-radius: 1rem;
margin-top: .5rem;
}
.search-bar>input{
    width:20rem;
    border-radius: 5rem;
    padding: 15px;
    border-color: rgb(96, 96, 132);
}
.search-button{
    position: absolute;
    width: 4rem;
    padding: .4rem;
    background-color: rgb(13, 5, 5);
    margin-left: 15rem;
    margin-top: .5rem; 
} 
.hero-title{
    margin-top: 3rem;
}
.stats{
   width:100%;
   justify-content: space-between; 
   margin-top: -4rem;
}
.stat>:nth-child(1)
{
    font-size:2rem;
}
.stat>:nth-child(1)>:last-child{
    color:orange;
}
.hero-title>h1{
    font-size: 3rem;
} 
 @media(max-width:640px){

     .hero-title>h1{
        margin-left: 29px;
        width: 240.667px;
        height: 114.2px;
        font-size: 2rem;
     }
     .orange-circle{
        height:3rem;
        width: 3rem;
        background-color: rgb(228, 136, 66);
        border-radius: 900px;
        position: absolute;
        right:30%;
        top: 11%;
        z-index: -1;
        margin-left: -64px;
        margin-right: -13px;
        margin-top: -2px;
     }
    
    .search-bar{
        margin-left: 19px;
margin-right: 32px;
margin-top: -223px;
margin-bottom: 0px;
width: 320.2px;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
bottom: 60px;
}
.search-bar>input{
    padding-top: 3px;
border-left-width: -9.4px;
border-left-style: solid;
padding-bottom: 19px;
margin-left: -11px;
margin-right: -21px;
width: 18rem;
border-radius: 5rem;
padding: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
border-color: rgb(96, 96, 132);
}
.search-bar>img{
    color: aliceblue;
    position: absolute;
    display: grid;
    place-items: center;
    left: 1rem;
    border-radius: 1rem;
    margin-top: .5rem;
    width: 27px;
    height: 29px;
  }

.search-button{
    position: absolute;
    width: 4rem;
    padding: .4rem;
    background-color: rgb(13, 5, 5);
    color: rgb(108, 72, 72);
    }
    .s1{
        display: flex;
flex-direction: column;
align-items: center;
align-items: flex-start;
margin-bottom: 20rem;
margin-left: 57px;
    }
  .s2{
    display: flex;
flex-direction: column;
align-items: center;
align-items: flex-start;
margin-bottom: 20rem;
margin-left: 0px;
margin-right: 39px;
  }
  .s3{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 20rem;
    margin-top: -297px;
   margin-left: 157px;
  }
  .s1>.secondary-text{
    margin-left: -23px;
  }
  .s2>.secondary-text{
    margin-left: -13px;
    margin-right: 4px;
  }
  .s3>.secondary-text{
    margin-left: -32px;
}
.hero-wrapper{
    margin-left: 0%;
    margin-right:0%;
    width: 375.2px;
height: 955px;
background-color: black;
position: relative;
padding-top: 3rem;
}
.img11{
    width: 21rem;
height: 22rem;
overflow: hidden;
border-radius: 200rem 200rem 0 0;
border: 1px solid #8d8693;
margin-bottom: 150px;
margin-top: -626px;
padding-bottom: 1px;
margin-bottom: 419px;
margin-left: 5px;
height: 23rem;
} 
.hero-container{
    justify-content:space-around ;
}
  }
   
      
    
