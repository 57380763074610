.firsttext{
    font-size: 250%;
}
.inner-container{
gap: 1.5rem;
background-color: aqua;
padding: 2rem;
border-radius: 10px;
border: 6px salmon;
text-align: center;
}