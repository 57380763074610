.v-container{
 justify-content: space-around;

}
.v-wrapper{
    margin-top: 2rem;
}

.orangeText>h1{
 color: rgb(72, 208, 208);
 margin-top: -600px;
 margin-left: 378px;

}
.primaryText{
    font-size: 122%;
    margin-bottom: 125px;
    padding-bottom: 6px;
    margin-top: 46px;
    margin-left: 43px;
}
.primaryTextt{
    font-size: 250%;
  
margin-bottom: 125px;
padding-bottom: 6px;
margin-top: 46px;
margin-left: 642px;
}
.secondaryText{
    margin-left: 719px;
  margin-right: 192px;

}
.v-left{
    margin-right: 700px;
}
.secondaryText>p{
    margin-left: 679px;
}
.accordion{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-left: 750px;
    width: 450px;
    height: 108px;
}
.secondarytxt{
    margin-left: -19px;
    height: 28.2px;
    width: 495px;
  }
  .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 0px;
    width: 100%;
    text-align: left;
    border: none;
  }
.accordionItem.expanded{
    box-shadow:var(--shadow);
    border-radius: 6px;
}
@media(max-width:650px){
    .v-viv{
        flex-direction: column;
    }
    .img12{
        width: 20rem;
       height: 23rem;
       margin-left: -122px;
    }
}