.c-container{
display: flex;
justify-content: space-around;

}
.c-container>img{
    width: 9rem;
}
@media (max-width: 650px){
.mycmp{
    flex-direction: column; 
    margin-left: 112px;
padding-top: 4px;
gap: 2rem;
}
}
    
