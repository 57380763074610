.image-cont{
    border-left-width: 4px;
    border-right-width: 4px;
    margin-left: 831px;
    border-bottom-width: 4px;
    border-top-width: 4px;
    margin-bottom: 122px;
margin-top: -144px;  
  }
  .left_side{
    margin-left: -1079px;
    width: 2630px;
    height: 176.233px;
  }
  .primary{
    font-size: 250%;
    margin-left: 181px;
  }
  .secondpara{
    margin-left: 256px;
  }
  .second{
    margin-left: 304px;
  }
  .shift{
    margin-left: 176px;
  }
  .num{
    margin-left: -28px;
  }
  .contactform{
    display: flex;
   flex-direction: column;
 
  }
  .form-control{
    display: flex;
flex-direction: column;
align-items: center;
margin-left: 1800px;
text-align: center;
margin-left: 1260px;
width: 402px;
height: 140px;
padding-right: 10px;
margin-right: 0px;
border-right-width: 0px;
border-right-style: solid;
margin-top: -8px;
  }
  .input{
    width: 410px;
margin-top: 8px;
border-radius: 4px;
text-align: center;
align-items: flex-start;
display: flex;
height: 16px;
margin-top: 12px;
  }
  .text-center{
    margin-left: 292px;
  }
  .btn:hover{
    cursor: pointer;
    background-color: blueviolet;
  }